import { PageProps, graphql } from "gatsby";
import * as React from "react";

const GuideArticleTemplate: React.FC<PageProps> = () => {
  return null;
};

export const query = graphql`
  query GuideArticleTemplateQuery($id: String!) {
    prismicJpNoviceGuideArticle(id: { eq: $id }) {
      uid
      data {
        article_title {
          text
        }
        header_title_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        meta_title {
          text
        }
        body {
          ...PrismicJpNoviceGuideArticleDataBodyCardTitleAndListDescription_data
          ...PrismicJpNoviceGuideArticleDataBodyFooterLinkButton_data
          ...PrismicJpNoviceGuideArticleDataBodyHalfView_data
          ...PrismicJpNoviceGuideArticleDataBodyHalfViewImageAndLinkButton_data
          ...PrismicJpNoviceGuideArticleDataBodyTitleAndIllustration_data
          ...PrismicJpNoviceGuideArticleDataBodyLinkButtonSection_data
        }
      }
    }
  }
`;

export default GuideArticleTemplate;
